import { useEffect, useState } from "react";
// // React-Router-Dom
import { useParams } from "react-router-dom";
// Translation
import Locale from "translations";
// React-Multi-Carsousel
import Carousel from "react-multi-carousel";
// Shared Components
import BreadCrumb from "modules/WebBuilder-V2/shared/BreadCrumb";
import ContentCardWrapper from "modules/WebBuilder-V2/shared/ContentCardWrapper";
import ServiceViewHeader from "modules/WebBuilder-V2/shared/ServiceViewHeader";
import OthersRequestForm from "modules/WebBuilder-V2/shared/RequestForm/OtherServiceRequestForm";
// import CustomRightArrow from "../../../shared/CustomRightArrow";
// import CustomLeftArrow from "../../../shared/CustomLeftArrow";
// Assets
import placeholderSrc from "assets/images/placeHoleder.png";
import otherImage from "assets/images/webBuilder/otherImg.png";
import { ReactComponent as RightTickIcon } from "assets/images/webBuilder/tick-circle.svg";
import { ReactComponent as XTickIcon } from "assets/images/webBuilder/red-x-circle.svg";
// Services
import { getBuilderServicerById } from "services/webBuilder";
// Context
import { useSBSState } from "context/global";
import { isObject, isArray } from "lodash"

// ---------------------------------------------------------------------------------------
const OthersServiceView = () => {
  //*** Hooks
  const { id } = useParams();
  const { webBuilder, productsBuilder } = Locale;
  const { locale,currency: currentCurrency } = useSBSState();

  // *** State
  const [othersData, setOthersData] = useState(null);
  const images = othersData?.images || [otherImage, otherImage];
  const links = [{ name: webBuilder?.others, url: "" }];


  const hasTerms = othersData?.terms && !isArray(othersData?.terms) && isObject(othersData?.terms) 

  const getBuilderServicer = async () => {
    const res = await getBuilderServicerById(id,currentCurrency);
    if (res?.status === 200) {
      setOthersData(res?.data?.data?.service);
    }
  };

  useEffect(() => {
    getBuilderServicer();
  }, []);

  let inclusions = []
  let exclusions = []

  if (othersData?.inclusion) {
   if (othersData?.inclusion?.[locale]?.length > 0) {
      inclusions = othersData?.inclusion?.[locale]
    } else if (othersData?.inclusion.en) {
      inclusions = othersData?.inclusion.en
    } else {
      inclusions = []
    }
  }

  if (othersData?.exclusion) {
    if (othersData?.exclusion?.[locale]?.length > 0) {
      exclusions = othersData?.exclusion?.[locale]
    } else if (othersData?.exclusion.en) {
      exclusions = othersData?.exclusion.en
    } else {
      exclusions = []
    }
  }

  // --------- JSX Code ---------
  return (
    <div className="service-view-wrapper">
      <BreadCrumb links={links} />

      <div className="service-view-container w-85 mx-auto pb-5">
        <ServiceViewHeader
          title={`${othersData?.name?.[locale] || othersData?.name?.en} (${
            webBuilder?.otherService
          })`}
          LocationCountry={othersData?.address?.[locale] || othersData?.address.en}
          // LocationCity={'Cairo'}
          price={othersData?.price}
          currency={othersData?.currency}
        />

        <div className="service-top-section mb-5">
          <div style={{ overflow: "hidden" }}>
            {/* Image Carsoul */}
            <div className="package-carousel">
              <Carousel
                responsive={{
                  desktop: { breakpoint: { max: 4000, min: 0 }, items: 1 },
                }}
                itemClass={"hotel-slide"}
                className=""
                slidesToSlide={1}
                keyBoardControl={true}
                // customRightArrow={<CustomRightArrow />}
                // customLeftArrow={<CustomLeftArrow />}
                renderButtonGroupOutside={true}
                shouldResetAutoplay={true}
                removeArrowOnDeviceType={["tablet", "mobile"]}
                infinite={true}
              >
                {images?.map((slide, index) => {
                  return (
                    <img
                      src={slide}
                      width={"100%"}
                      alt=""
                      key={slide || index}
                      onError={(e) => {
                        e.target.src = placeholderSrc;
                      }}
                    />
                  );
                })}
              </Carousel>
            </div>

            {/* Description */}
            {othersData?.description && (
              <div className="mt-4">
                <ContentCardWrapper title={webBuilder?.description}>
                  {/* body of ContentCard */}
                  <p className="card-body-text">
                    {othersData?.description[locale] ||
                      othersData?.description.en}
                  </p>
                </ContentCardWrapper>
              </div>
            )}

            {/* Include & Exclude */}
            {(inclusions?.length > 0 ||
              exclusions?.length > 0) && (
              <div className="mt-4">
                <ContentCardWrapper title={webBuilder?.includeExeclude}>
                  {/* body of ContentCard */}
                  <div className="d-flex">
                    {inclusions?.length > 0 && (
                      <div className="w-100">
                        {inclusions?.map((item, index) => (
                          <p className="gray-text">
                            <RightTickIcon /> {item}
                          </p>
                        ))}
                      </div>
                    )}

                    {exclusions?.length > 0 && (
                      <div className="w-100">
                        {exclusions?.map((item, index) => (
                          <p className="gray-text">
                            <XTickIcon /> {item}
                          </p>
                        ))}
                      </div>
                    )}
                  </div>
                </ContentCardWrapper>
              </div>
            )}

            {/* terms */}
            {hasTerms && (
              <div className="mt-4">
                <ContentCardWrapper title={productsBuilder.termsAndConditions}>
                  {/* body of ContentCard */}
                  <p className="card-body-text">
                    {othersData?.terms[locale] || othersData?.terms?.en}
                  </p>
                </ContentCardWrapper>
              </div>
            )}
            {/* Policies */}
            {othersData?.policy && (
              <div className="mt-4">
                <ContentCardWrapper title={webBuilder?.policies}>
                  {/* body of ContentCard */}
                  <p className="card-body-text">
                    {othersData?.policy[locale] || othersData?.policy.en}
                  </p>
                </ContentCardWrapper>
              </div>
            )}
          </div>

          {/* OtherRequest Form */}
          <div style={{ height: "fit-content" }}>
            <OthersRequestForm data={othersData} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OthersServiceView;
