import Locale from "translations";
import { ReactComponent as CalendarIcon } from "assets/images/webBuilder/calendar-2.svg";
import { ReactComponent as Hotelcon } from "assets/images/webBuilder/hotel-black.svg";
import { ReactComponent as StarIcon } from "assets/images/webBuilder/star.svg";
import { ReactComponent as GoldenStarIcon } from "assets/images/webBuilder/golden-star.svg";
import { Link } from "react-router-dom";
import moment, { now } from "moment";
import useExchangeRate from "hooks/useExchangeRate";
import { useSBSState } from "context/global";


export default function PackageCardThemeFour(props) {
  const {
    image,
    name,
    name_localized,
    departure_date_at,
    return_date_at,
    stars,
    product_uuid,
    hotelName,
    price,
    initialName,
    currency,
    sourceModule,
  } = props;
  const { locale } = useSBSState();
  const { webBuilder } = Locale;
  const { exchangedPrice, exchangeCurrency } = useExchangeRate({ price, currency });
  const packageUrl = sourceModule === "service-builder" ? `/package-service/${product_uuid}` : `/package/${product_uuid}`;
  const packageName = name_localized?.[locale] ? name_localized?.[locale] : name_localized?.en || (name || initialName);

  const isFromServiceBuilder = sourceModule === "service-builder";

  return (
    <Link to={packageUrl} key={product_uuid}>
      <div className={`package-card-holder`}>
        <div className={`package-img`}>
          {image ? (
            <img src={image} alt="our package" />
          ) : (
            <img
              src="https://back-sbs.dev.safa.asia/api/v1/files/fetch/476c2074-0c5e-4e82-a891-d5099530f7e6"
              alt="Safa Visa"
            />
          )}
        </div>

        <div className="card-caption-holder">
          <div className="rate mb-2">
            {[...Array(5)].map((i, index) =>
              index < stars ? (
                <GoldenStarIcon key={index} />
              ) : (
                <StarIcon key={index} />
              )
            )}
          </div>

          <h3 className="card-title">{packageName}</h3>

          <div className="d-flex align-items-center mb-2">
            <CalendarIcon className="me-1" />
            <span>
              {isFromServiceBuilder
                ? moment(departure_date_at || now()).format("DD MMM YYYY")
                : `${moment(departure_date_at || now()).format("DD MMM YYYY")} - ${moment(return_date_at || now()).format("DD MMM YYYY")}`}
            </span>
          </div>

          <div className="d-flex align-items-center mb-2">
            <Hotelcon className="me-1" />
            {hotelName?.[locale] || hotelName?.en || "-"}
          </div>

          <div className="d-flex justify-content-end mt-auto">
            <div className="price">
              <span className="label">{webBuilder?.from}</span>
              <p>
                {exchangedPrice}{" "}
                <span>{exchangeCurrency}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}
