import React from "react";
import { ReactComponent as AddIcon } from "assets/images/webBuilder/add-square40.svg";
import { ReactComponent as StarIcon } from "assets/images/webBuilder/star.svg";
import { ReactComponent as GoldenStarIcon } from "assets/images/webBuilder/golden-star.svg";
import { ReactComponent as LocationIcon } from "assets/images/webBuilder/location-outline-icon.svg";
import { useHistory } from "react-router-dom";
import Locale from "translations";
import useExchangeRate from "hooks/useExchangeRate";
import { useSBSState } from "context/global";

export default function ServiceCardWithButton(props) {
  const { webBuilder } = Locale;
  const { locale } = useSBSState();
  const history = useHistory();
  let {
    openEditModal,
    name,
    name_localized,
    price,
    image,
    currency,
    stars,
    isNew,
    cardType,
    product_uuid,
    initialName,
    locationName,
    sourceModule
  } = props;

  const localeName = name_localized?.[locale] || name;

  const trimName = localeName
    ? localeName?.length > 22
      ? `${localeName?.substr(0, 22)}...`
      : localeName
    : initialName;
  const hotelRating = +stars > 5 ? 5 : +stars;

  const { exchangedPrice, exchangeCurrency } = useExchangeRate({ price, currency });

  const cardUrl = sourceModule === "service-builder" ? `/${cardType}-service/${product_uuid}` : `/${cardType}/${product_uuid}`;


  return (
    <article
      className={`${cardType}-carousel-card service-card-with-btns`}
      key={product_uuid}
    >
      <div className="card-details">
        <div className={`card-img ${isNew ? " empty-service" : ""}`}>
          {image ? (
            <img src={image} alt={trimName} />
          ) : isNew ? (
            <button
              className="btn add-service-btn"
              onClick={() => openEditModal(props)}
            >
              <AddIcon />
            </button>
          ) : null}
        </div>
        <div className="card-content">
          <h4 className="service-name" title={name}>
            {trimName}
          </h4>
          {cardType === "hotels" && (
            <div className="rate mb-2">
              {[...Array(5)].map((i, index) =>
                index < hotelRating ? (
                  <GoldenStarIcon key={index} />
                ) : (
                  <StarIcon key={index} />
                )
              )}
            </div>
          )}
          <div className="location mt-2">
            <LocationIcon />
            <span>{locationName?.[locale] || locationName?.en || locationName}</span>
          </div>
        </div>
        <div className="card-footer">
          <div>
            <span className="font-grey-light">{webBuilder.startFrom}</span>
            <div className="d-flex align-items-center">
              <p className="amount me-1 truncate-price"> {exchangedPrice} </p>
              <div>
                <span className="currency">{exchangeCurrency}</span>
                {cardType !== "tours" &&
                  <span className="font-grey-light">/{webBuilder.night}</span>
                }
              </div>
            </div>
          </div>
          <button
            className="btn web-builder-filled-btn"
            onClick={() => {
              if (product_uuid) {
                history.push(cardUrl);
              }
            }}
          >
            {webBuilder.bookNow}
          </button>
        </div>
      </div>
    </article>
  );
}
