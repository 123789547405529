import Locale from 'translations';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import useExchangeRate from 'hooks/useExchangeRate';
import { useSBSState } from 'context/global';
import { ArrowRightIcon, LocationMarkerIcon, StarIcon } from 'modules/WebBuilder-V2/shared/icons';

export default function ThemeSevenHotelCard(props) {
  let { name,
    name_localized,
    price,
    image,
    currency,
    stars,
    product_uuid,
    initialName,
    locationName,
    city,
    sourceModule
  } = props;
  const { webBuilder } = Locale;
  const { locale } = useSBSState();
  const { exchangedPrice, exchangeCurrency } = useExchangeRate({ price, currency });

  const hotelUrl = sourceModule === "service-builder" ? `/hotels-service/${product_uuid}` : `/hotels/${product_uuid}`;

  return (
    <div className="theme_hotel_card">
      <div className="image">
        <div className={`service-img`}>
          <img src={image} alt={name} onError={(e) => {
            e.target.onerror = null;
            e.target.src = "https://back-sbs.staging.safa.asia/api/v1/files/fetch/1847cef6-e5c0-40bb-b1dd-d2dc7827cd58";
          }} />
        </div>

      </div>
      <div className="content">
        <div className="location">
          <p className='destination_name'>
            <LocationMarkerIcon />
            <span>{locationName?.[locale] || locationName?.en || locationName}</span>
            {city &&
              <>
                <span className='mx-1'>/</span>
                <span>{city}</span>
              </>
            }
          </p>
        </div>
        <div className="package_name">
          <h5 className='package_title'>
            <Link to={hotelUrl}>
              {name_localized?.[locale] || name || initialName}
            </Link>
          </h5>
          <div className="package_rating">
            {[...Array(5)].map((i, index) =>
              index < stars ? (
                <StarIcon fill="#F5B950" width="16" height="14" />
              ) : (
                <StarIcon fill="#DDDCDC" width="16" height="14" />
              )
            )}
          </div>
        </div>
        <div className="package_footer">
          <div className="pricing">
            <p className="from_text">{webBuilder.from}</p>
            <p className="actual_price">{exchangedPrice} {" "}
              <span>{exchangeCurrency}</span>
            </p>
          </div>
          <div className="cta">
            <Link to={hotelUrl} className='theme_btn'>
              <span className="btn_content">
                <ArrowRightIcon className="mirror-rtl" />
              </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}