import ServicesContent from "modules/WebBuilder/shared/ServicesContent";
import TransferCarousel from "./Carousel";
import TransferCardTheme4 from "./TransferCardTheme4";
import ServiceSlider from "modules/WebBuilder-V2/shared/ServiceSlider";

export default function TransferTheme4View({ details }) {
  const { items, title, body } = details;

  return (
    <div className="web-builder-content custom-container home-page-transfer-section">
      <div className="service-top-section">
        <ServicesContent header={title} body={body} />
      </div>
      <div className="services-carousel-container">
        <ServiceSlider items={items} render={(item) => <TransferCardTheme4 item={item}/>} />
      </div>
    </div>
  );
}
