import React, { useState } from 'react'
import Locale from 'translations';
import { ReactComponent as CloseIcon } from "assets/images/webBuilder/close-nav.svg";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { useWebBuilderState } from 'context/webBuilder';
import { useHistory } from "react-router-dom";
import { useSBSState } from 'context/global';
import useWindowDimensions from 'hooks/useWindowDimensions';


export default function MainNav({ handleOpenMobileNav, navRef }) {
  const { webBuilder } = Locale;
  const { locale } = useSBSState();
  const { hero, mainMenu } = useWebBuilderState();
  const history = useHistory();
  const [moreDropDownOpen, setMoreDropDownOpen] = useState(false);

  const { width } = useWindowDimensions();


  const header = hero?.header;
  const headerBg = header?.layout?.background === "white";
  let originalVisibleMenuItems = mainMenu?.filter((item) => item?.visible && item?.id !== "about-page");
  const visibleMenuItems = width > 992 && originalVisibleMenuItems?.length > 3 ? originalVisibleMenuItems?.slice(0, 3) : originalVisibleMenuItems;
  let restOfVisibleItems = width > 992 && originalVisibleMenuItems?.length > 3 ? originalVisibleMenuItems?.slice(3) : null;


  return (
    <nav className="header-nav" ref={navRef}>
      <button className="close-nav" onClick={handleOpenMobileNav}>
        <CloseIcon />
      </button>

      <ul className="main-nav">
        <li
          onClick={() => {
            history.push(`/`);
            handleOpenMobileNav();
          }}
          className={headerBg ? "text-secondary-color" : ""}
        >
          {webBuilder.home}
        </li>
        <li
          onClick={() => {
            history.push(`/about-us`);
            handleOpenMobileNav();
          }}
          className={headerBg ? "text-secondary-color" : ""}
        >
          {webBuilder.aboutus}
        </li>
        {visibleMenuItems?.map((item) => {
          return (
            <li
              key={item?.id}
              className="position-relative"
              onClick={() => {
                if (!item?.subNavs || item?.subNavs?.length === 0) {
                  let url = true
                    ? `/${item.url}`
                    : `/web-builder-v2/preview/${item?.url}`;

                  history.push({ pathname: `${url}` });
                  handleOpenMobileNav();
                }
              }}
            >
              {item?.subNavs?.length > 0 ? null : (
                <span>{item?.title?.[locale]}</span>
              )}
              {/********************************************** sub navs *************************************************************/}
              {item?.subNavs?.length > 0 && (
                <UncontrolledDropdown>
                  <DropdownToggle caret className={`service-dropdown`}>
                    {item?.title?.[locale]}
                  </DropdownToggle>

                  <DropdownMenu right>
                    {item?.subNavs.map((subNav) => (
                      <DropdownItem
                        key={subNav.id}
                        onClick={() => {
                          let url = true
                            ? `/${subNav.url}`
                            : `/web-builder-v2/preview/${subNav?.url}`;

                          history.push({ pathname: `${url}` });
                          handleOpenMobileNav();
                        }}
                      >
                        {subNav.title?.[locale]}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
            </li>
          );
        })}

        {/* more dropdown */}
        {visibleMenuItems?.length !== originalVisibleMenuItems?.length &&
          <li className="position-relative">
            <Dropdown
              isOpen={moreDropDownOpen}
              toggle={() => setMoreDropDownOpen(!moreDropDownOpen)}
            // onClick={() => toggleMoreDropdown(moreDropDownOpen)} 
            >
              <DropdownToggle caret className={`service-dropdown`}>
                {webBuilder.more}
              </DropdownToggle>
              <DropdownMenu className="more-dropdown">
                {restOfVisibleItems.map((item, index) => {
                  if (item?.subNavs?.length > 0) {
                    return (
                      <UncontrolledDropdown key={`more-${item.id}-${index}`}>
                        <DropdownToggle caret>
                          {item.title?.[locale]}
                        </DropdownToggle>
                        <DropdownMenu right className="more-subnav-dropdown">
                          {item?.subNavs.map((subNav) => {

                            return (
                              <DropdownItem
                                key={subNav.id}
                                onClick={() => {
                                  let url = true
                                    ? `/${subNav.url}`
                                    : `/web-builder-v2/preview/${subNav?.url}`;
                                  setMoreDropDownOpen(false)
                                  history.push({ pathname: `${url}` });
                                  handleOpenMobileNav();
                                }}
                              >
                                {subNav.title?.[locale]}
                              </DropdownItem>
                            )
                          })}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    )
                  }
                  return (
                    <DropdownItem
                      key={item.id}
                      onClick={() => {
                        let url = true
                          ? `/${item.url}`
                          : `/web-builder-v2/preview/${item?.url}`;

                        history.push({ pathname: `${url}` });
                        handleOpenMobileNav();
                      }}
                    >
                      {item.title?.[locale]}
                    </DropdownItem>
                  )
                })}
              </DropdownMenu>
            </Dropdown>
          </li>
        }
      </ul>
    </nav>
  )
}