import {
  AdultsField,
  // AdultsField,
  CheckInField,
  CheckOutField,
  ChildrenField,
  EmailField,
  // NationalityField,
  // ChildrenField,
  NoteField,
  // PhoneField,
  // RoomsTypeInput,
  UserNameField,
  WhatsAppField,
} from "modules/WebBuilder-V2/shared/RequestForm/InputFields";
import React, { useEffect, useMemo, useState } from "react";
import Locale from "translations";
import validate, { isFormValid } from "helpers/validate";
import PricingCard from "modules/WebBuilder-V2/shared/RequestForm/PricingCard";
import SelectField from "components/Form/SelectField/SelectField";
import { ArrowDown, ProfileIcon } from "modules/WebBuilder-V2/shared/icons";
import { calcPackageHotelTotalPrice, generateOptionsByCount, generateRoomsTypes } from "modules/WebBuilder-V2/shared/RequestForm/calculateServicePrice";
import { handleResponesNotification } from "helpers/utils";
import moment from "moment";
import { sendServiceBuilderRequest } from "services/webBuilder";
import { useParams } from "react-router-dom";
import { useSBSState } from "context/global";
import PhoneField from "components/Form/PhoneField/PhoneField";


const initialFormObj = {
  values: {
    check_in: null,
    check_out: null,
    user_name: "",
    email: "",
    phone_code: "",
    phone: "",
    whatsapp: "",
    message: "",
  },
  errors: {},
}

export default function PackageRequestForm({ packageData, hasHotels }) {
  // hooks
  const { webBuilder, rooms: roomsLocale } = Locale;
  const { id } = useParams();
  const { allCountries } = useSBSState();



  // states
  const [formState, setFormState] = useState({
    ...initialFormObj,
    values: {
      ...initialFormObj.values,
      ...(hasHotels ? { rooms: null } : { adults: 1, children: 0 })
    }
  });


  const roomTypesList = useMemo(() => generateRoomsTypes(packageData, roomsLocale), [packageData, roomsLocale]);

  const packagePrice = useMemo(() => {
    return calcPackageHotelTotalPrice({
      serviceData: packageData,
      checkInDate: formState.values.check_in,
      checkOutDate: formState.values.check_out,
      roomsData: formState.values.rooms?.roomsData,
      hasHotels,
      adultCount: formState.values.adults,
      childrenCount: formState.values.children,
    })
  }, [formState.values.adults, formState.values.check_in, formState.values.check_out, formState.values.children, formState.values.rooms?.roomsData, hasHotels, packageData]);


  function checkFormErrors() {
    let submitErrors = {};
    Object.keys(formState.values).forEach((key) => {
      submitErrors = {
        ...submitErrors,
        ...validate(
          { name: key, value: formState.values[key] },
          { required: key !== "message" }
        ),
      };
    });

    setFormState({
      ...formState,
      errors: submitErrors,
    });
    return submitErrors;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formErrors = checkFormErrors();
    console.log(formErrors);

    if (!isFormValid(formErrors)) return;

    let requestBody = {
      date_from: formState.values.check_in ? moment(formState.values.check_in).locale('en').format("YYYY-MM-DD") : null,
      date_to: formState.values.check_out ? moment(formState.values.check_out).locale('en').format("YYYY-MM-DD") : null,
      ...(hasHotels && {
        rooms: formState.values.rooms?.roomsData?.map(room => {
          return {
            room_type: room?.roomType?.room_type,
            adults_count: +room?.adultCount,
            children_count: +room?.childrenCount,
          }
        })
      }),
      ...(!hasHotels && {
        adults: formState.values.adults,
        childs: formState.values.children,
        rooms: []
      }),

      notes: formState.values.notes,
      client: {
        name: formState.values.user_name,
        email: formState.values.email,
        phone: formState.values?.phone_code?.phone_code + formState.values.phone,
        whatsapp_number: formState.values?.phone_code?.phone_code + formState.values.whatsapp,
      }
    }
    let res = await sendServiceBuilderRequest({ id, data: requestBody, serviceType: "package" });
    if (res?.status === 200) {
      handleResponesNotification({ alertType: "success", message: res?.data?.message || "Request Sent Successfully" });
      setFormState(initialFormObj);
    }
  };




  return (
    <form className="request-form" onSubmit={handleSubmit}>
      <div className="header">{webBuilder.requestForm}</div>

      <div className="row mx-0 pb-0">
        {/* hotel checkin */}
        <div className="col-6">
          <CheckInField
            formState={formState}
            formStateSetter={setFormState}
          />
        </div>

        {/* hotel checkout */}
        <div className="col-6">
          <CheckOutField
            formState={formState}
            formStateSetter={setFormState}
          />
        </div>

        {/* guests rooms */}
        <div className="col-12 mt-1">
          {packageData && hasHotels &&
            <GuestsRoomsInput
              roomTypes={roomTypesList}
              formState={formState}
              formStateSetter={setFormState}
            />
          }
          {packageData && !hasHotels &&
            <>
              <AdultsField
                formState={formState}
                formStateSetter={setFormState}
              />
              <div className="mt-2">
                <ChildrenField
                  formState={formState}
                  formStateSetter={setFormState}
                />
              </div>
            </>
          }
        </div>

        {/*user name */}
        <div className="col-12 mt-1">
          <UserNameField
            formState={formState}
            formStateSetter={setFormState}
          />
        </div>

        {/* email */}
        <div className="col-12 mt-1">
          <EmailField
            formState={formState}
            formStateSetter={setFormState}
          />
        </div>

        {/* nationality */}
        {/* <div className="col-12 mt-1">
          <NationalityField
            formState={formState}
            formStateSetter={setFormState}
          />
        </div> */}

        <div className="col-12 mt-2 phone">
          <PhoneField
            countriesLookup={allCountries}
            phoneCode={formState.values?.phone_code}
            phone={formState.values?.phone}
            onSelectPhoneCode={(e) => {
              setFormState({
                ...formState,
                values: { ...formState.values, phone_code: e },
                errors: {
                  ...formState.errors,
                  ...validate(
                    { name: "phone_code", value: e },
                    { required: true }
                  ),
                },
              });
            }}
            onChangePhone={(e) => {
              setFormState({
                ...formState,
                values: { ...formState.values, phone: e.target.value },
                errors: {
                  ...formState.errors,
                  ...validate(
                    { name: "phone", value: e.target.value },
                    { required: true }
                  ),
                },
              });
            }}
            phoneCodeColor={
              formState.errors?.phone_code?.required ? "danger" : ""
            }
            phoneCodeError={formState.errors?.phone_code}
            phoneColor={formState.errors?.phone?.required ? "danger" : ""}
            phoneError={formState.errors?.phone}
          />
        </div>

        <div className="col-12 mt-2">
          <WhatsAppField formState={formState} formStateSetter={setFormState} />
        </div>

        <div className="col-12 mt-2">
          <NoteField formState={formState} formStateSetter={setFormState} />
        </div>

        <div className="col-12 mb-1">
          <hr />
        </div>

        <div className="col-12">
          <PricingCard
            totalPrice={packagePrice?.totalPriceAfterTaxes}
            adultPrice={packagePrice?.adultsPrices}
            childPrice={packagePrice?.childrenPrices}
            taxes={packagePrice?.totalTaxes}
            currency={packagePrice?.totalPriceAfterTaxes ? packageData?.currency : ""}
          />
        </div>

        <div className="col-12 mt-3">
          <button type="submit" className="btn submit-btn w-100">
            {webBuilder.sendRequest}
          </button>
        </div>
      </div>
    </form>
  );
}

export function GuestsRoomsInput({ roomTypes, formState, formStateSetter, isFormSubmited }) {
  const { rooms: roomsLocale, commons, webBuilder } = Locale;
  const [rooms, setRooms] = useState({
    count: 0,
    roomsData: [],
  });

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [distinctSelectedRooms, setDistinctSelectedRooms] = useState([]);
  const maxRoomsCount = 5;
  const maxChildrenPerRoom = 2;

  function toggleDropdown() {
    setIsDropdownOpen(!isDropdownOpen)
  }

  function handleRoomsCount(value) {
    const currentCount = rooms.count;
    let roomsClone = { ...rooms };
    if (currentCount === value || typeof (+value) !== "number") return;

    if (currentCount > +value) {
      // remove from end of the array
      const removedRoomsCount = currentCount - (Number(value) || 0);
      roomsClone = { roomsData: roomsClone.roomsData.slice(0, -removedRoomsCount), count: value };
    } else if (currentCount < +value) {
      // push inside roomsData by value count
      const addedRoomsCount = Number(value) - currentCount;
      const newRooms = Array.from({ length: (addedRoomsCount || 0) }, () => ({ roomType: null, adultCount: 0, childrenCount: 0 }))
      roomsClone = { count: value, roomsData: [...roomsClone.roomsData, ...newRooms] }
    }

    setRooms(roomsClone)
  }

  function handleRoomChanges({ key, value, index }) {
    let roomsDataClone = [...rooms.roomsData];
    roomsDataClone[index][key] = value;
    setRooms({ ...rooms, roomsData: roomsDataClone })
  }

  function handleSelectRoomsTypesAndCounts() {
    formStateSetter({
      ...formState,
      values: {
        ...formState.values,
        rooms
      },
      errors: {
        ...formState.errors,
        ...validate(
          { name: "rooms", value: rooms },
          { required: true }
        ),
      },
    });
    getDistinctSelectedRooms();
    toggleDropdown()
  }

  function getDistinctSelectedRooms() {
    let selectedRooms = {};
    rooms?.roomsData.forEach(room => {
      const roomType = room?.roomType?.room_type;
      if (selectedRooms[roomType] === undefined) {
        selectedRooms[roomType] = { type: roomType, count: 1 };
      } else {
        selectedRooms[roomType] = { type: roomType, count: selectedRooms[roomType].count + 1 };
      }
    });
    setDistinctSelectedRooms(() => Object.values(selectedRooms))
  }

  useEffect(() => {
    if (isFormSubmited) {
      setRooms({ count: 0, roomsData: [] });
      setDistinctSelectedRooms([]);
    }
  }, [isFormSubmited])


  return (
    <div className={`service-room-input-container`}>

      <div className={`rooms-input-header ${formState.errors?.rooms?.required ? " control-field--danger" : ""} `} onClick={toggleDropdown}>
        <ProfileIcon size={25} color="#707170" />

        {distinctSelectedRooms.length > 0 ?
          distinctSelectedRooms.map((room, index) => {
            return (
              <span key={room.type} style={{ color: "#000000" }}>
                {room?.count} {roomsLocale[`x${room?.type}`]}
                {index < distinctSelectedRooms.length - 1 ? ", " : ""}
              </span>
            )
          })
          :
          <span>{webBuilder.guestsRooms}</span>
        }
        <ArrowDown className="arrow-icon" />
      </div>
      {formState.errors?.rooms?.required &&
        <small className="control-field__feedback control-field__feedback--danger d-block error-message">{commons.isRequired}</small>
      }

      <div className={`rooms-dropdown ${isDropdownOpen ? "opened" : ""}`}>
        <div className="rooms-count">
          <span>{webBuilder.roomCount}</span>
          <div className="col-9">
            <SelectField
              haslabel={false}
              placeholder={webBuilder.select}
              name="reservation-rooms-count"
              id="reservation-rooms-count"
              options={generateOptionsByCount(maxRoomsCount)}
              value={rooms.count || ""}
              onChange={(e) => handleRoomsCount(+e.value)}
            />
          </div>
        </div>

        <ul className="rooms-list mt-3">
          {rooms.roomsData.map((room, index) => {
            return (
              <>
                <li className="room-item row mx-0 p-0">
                  <div className="col-md-5 col-12">
                    <SelectField
                      label={`${webBuilder.room}${index + 1} ${webBuilder.type}`}
                      placeholder={webBuilder.select}
                      name={`room-type-${index}`}
                      id={`room-type-${index}`}
                      options={roomTypes}
                      value={room?.roomType?.name}
                      onChange={(e) => handleRoomChanges({ key: "roomType", value: e, index })}
                    />
                  </div>
                  <div className="col-md-4 col-12">
                    <SelectField
                      label={webBuilder.adult}
                      placeholder={webBuilder.select}
                      name={`room-adult-${index}`}
                      id={`room-adult-${index}`}
                      options={generateOptionsByCount(room?.roomType?.room_type)}
                      value={room?.adultCount}
                      onChange={(e) => handleRoomChanges({ key: "adultCount", value: +e.value, index })}
                      disabled={!room?.roomType}
                    />
                    <small>{webBuilder.yrs12}</small>
                  </div>
                  <div className="col-md-3 col-12">
                    <SelectField
                      label={webBuilder.child}
                      placeholder={webBuilder.select}
                      name={`room-child-${index}`}
                      id={`room-child-${index}`}
                      options={[{ id: 0, value: 0, name: "0" }, ...generateOptionsByCount(maxChildrenPerRoom)]}
                      value={`${room?.childrenCount}`}
                      onChange={(e) => handleRoomChanges({ key: "childrenCount", value: +e.value, index })}
                      disabled={!room?.roomType}
                    />
                    <small>({webBuilder?.yrs2To12})</small>
                  </div>
                </li>
                {index < rooms.roomsData.length - 1 &&
                  <hr className="my-3" />
                }
              </>
            )
          })}

        </ul>

        <div className="add-rooms">
          <button type="button" className="btn submit-btn"
            disabled={rooms.roomsData.some(room => room?.adultCount <= 0) || rooms.roomsData.length === 0}
            onClick={(e) => {
              handleSelectRoomsTypesAndCounts()
            }}
          >
            {webBuilder.addRooms}
          </button>
        </div>
      </div>

    </div>
  )
}

