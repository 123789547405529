import { store } from "react-notifications-component";

export function formatPrice(price, options = {}) {
  if (!price) return "0,00";

  const defaultOptions = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  const formatter = new Intl.NumberFormat("en-US", {
    ...defaultOptions,
    ...options,
  });
  return formatter.format(price || 0);
}

export function calcExchangeRatePrice(rate, price, preventPriceFormating) {
  if (!rate || rate === 0) return formatPrice(price);
  return preventPriceFormating ? (price * rate) : formatPrice(price * rate);
}


export function unLocalizeDate(date) {
  if (!date) return null;
  const arabicNumbers = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
  const englishNumbers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
  let englishDate = date;
  for (let i = 0; i < arabicNumbers.length; i++) {
    englishDate = englishDate.replace(
      new RegExp(arabicNumbers[i], "g"),
      englishNumbers[i]
    );
  }
  return englishDate;
}

export const handleFileURL = (uri) => {
  if (!uri) return null;
  else {
    if (String(uri).includes("http://") || String(uri).includes("https://"))
      return uri;
    return process.env.REACT_APP_FILEUPLOAD_URL + "/fetch/" + uri;
  }
};

/**
 * @param {string | object} urlObj - the value of logo, may be a string or an object of selected languages
 * @param {string | undefined} url - the value of selected language url.
 * @returns {ReturnType} - Description of the return value (optional).
 */

export const logoURL = (urlObj, url) => {
  if (!urlObj) return null;
  if (typeof urlObj === "string") {
    return handleFileURL(urlObj);
  }
  return handleFileURL(url);
};



export function handleResponesNotification({ alertType, message, title }) {
  store.addNotification({
    title: title || "",
    message: message,
    type: alertType,
    insert: "top",
    container: "top-right",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
      duration: 3000,
      onScreen: true,
      pauseOnHover: true,
    },
  });
}
